/* bubblechart */
.bubblechart{
    border-radius: 5px;
    margin: 0 auto;
    font-family: 'Lato';
    padding: 0;
    max-width: 424px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    .bubblechart svg{
        filter: drop-shadow(0.2rem 0.3rem 0.125rem rgba(10,10,10,.1));
        max-width: 100%;
        margin-bottom: 4px;
    }
@media all and (max-width: 1024px){
    .bubblechart{
        max-width: 440px;
    }
    .bubblechart_detail{
        max-width: 440px !important;
    }
    .bubblechart_detail li{
        flex-direction: column;
        align-items: flex-start !important;
        line-height: 1.4;
        padding: 8px 0 !important;
    }

}


.bubblechart_detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    border-radius: 5px;
    width: 100%;
    max-width: 880px;
    padding: 32px 16px;
    margin: 16px 0;
}
.bubblechart_detail li{
    padding: 10px 8px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
}
.bubblechart_detail li div{
    min-width: 100px;
    line-height: 1.6;
}
.bubblechart_detail li span{
    font-size: 0.84rem;
    font-weight: 100;

    line-height: 1.5;
}